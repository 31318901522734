module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Company Name","short_name":"CompanyName","start_url":"/","background_color":"#fff","theme_color":"#ccc","display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/@giraldomac/gatsby-theme-mindmerge/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"en_IE","url":"https://www.exesalibero-pharma.com","site_name":"ExesaLibero Pharma, Inc"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ExesaLibero Pharma, Inc","short_name":"ExesaLibero","start_url":"/","background_color":"#fff","theme_color":"#ccc","display":"standalone","icon":"./src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"010ed247b32af53b39315d9cd35961b9"},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"exesalibero","accessToken":"MC5YMkQtSGhJQUFDZ0FjS0Rq.77-977-977-977-977-977-9eSjvv73vv73vv73vv73vv703ZO-_vVVd77-977-977-977-977-977-9dO-_vRET77-977-977-977-9","defaultLang":"en-us","omitPrismicScript":true,"previews":true,"pages":[{"type":"Post","match":"/news/:uid","path":"/news-prev","component":"/opt/build/repo/src/templates/post.js"}],"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
